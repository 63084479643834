.App {
  text-align: center;
  background-color: #121212;
  width: 100vw;
  height: 100vh;
  /* display: flex; */
  color: white;
  overflow-y: auto;
}

body {
  padding: 0%;
  margin: 0;
}

nav {
  height: 60px;
  width: 100%;
  margin: 0;
  border-bottom: 1px solid grey;
  display: grid;
  place-items: center;
}

nav h1 {
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  color: white;
  font-size: 45px;
}
.game {
  width: 100vw;
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  padding-top: 50px;
  flex-direction: column;
}

.board {
  width: 450px;
  height: 550px;
  border: 1px solid #121212;
  display: flex;
  flex-direction: column;
}

.row {
  flex: 33%;
  display: flex;
  flex-direction: row;
  margin: 5px;
}

.letter {
  flex: 33%;
  height: 100%;
  border: 1px solid grey;
  margin: 5px;
  display: grid;
  place-items: center;
  font-size: 30px;
  font-weight: bolder;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

#correct {
  background-color: #528d4e;
}

#almost {
  background-color: #b49f39;
}

#error {
  background-color: #3a393c;
}

#blank {
  background-color: #121212;
}

.keyboard {
  width: 700px;
  height: 300px;
  margin-top: 60px;
}

.line1 {
  flex: 33%;
  display: flex;
  flex-direction: row;
  display: flex;
  justify-content: center;
  margin: 5px;
}
.line2 {
  flex: 33%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 5px;
}
.line3 {
  flex: 33%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 5px;
}

.key {
  width: 50px;
  height: 70px;
  margin: 5px;
  border-radius: 4px;
  display: grid;
  place-items: center;
  font-size: 20px;
  background-color: grey;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
}

#big {
  width: 100px;
}
#disabled {
  background-color: #3a393c;
}

@media only screen and (max-width: 600px){
  .App {
    text-align: center;
    background-color: #121212;
    width: 100vw;
    height: 100vh;
    color: white;
  }

  body {
    padding: 0%;
    margin: 0;
  }

  nav {
    height: 4vh;
    width: 100%;
    margin: 0;
    border-bottom: 1px solid grey;
    display: grid;
    place-items: center;
  }

  nav h1 {
    margin: 0;
    font-family: Helvetica, Arial, sans-serif;
    color: white;
    font-size: 1.2rem;
  }
  .game {
    width: 100vw;
    height: calc(100vh - 70px);
    display: flex;
    align-items: center;
    padding-top: 1vh;
    flex-direction: column;
  }

  .board {
    width: 90vw;
    height: 63vh;
    border: 1px solid #121212;
    display: flex;
    flex-direction: column;
  }

  .row {
    flex: 33%;
    display: flex;
    flex-direction: row;
    margin: 1px;
  }

  .letter {
    flex: 33%;
    height: 80%;
    border: 1px solid grey;
    margin: 4px;
    display: grid;
    place-items: center;
    font-size: 1em;
    font-weight: bolder;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
  }

  #correct {
    background-color: #528d4e;
  }

  #almost {
    background-color: #b49f39;
  }

  #error {
    background-color: #3a393c;
  }

  #blank {
    background-color: #121212;
  }

  .keyboard {
    width: 100vw;
    height: 40vh;
    margin-top: 0vh;
  }

  .line1 {
    flex: 33%;
    display: flex;
    flex-direction: row;
    display: flex;
    justify-content: center;
    margin: 1px;
  }
  .line2 {
    flex: 33%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 1px;
  }
  .line3 {
    flex: 33%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 1px;
  }

  .key {
    width: 2.5em;
    height: 3em;
    margin: 0.1em;
    border-radius: 4px;
    display: grid;
    place-items: center;
    font-size: 1em;
    background-color: grey;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
    user-select: none;
  }

  #big {
    width: 5em;
    font-size: 0.8em;
    height: 3.73em;
  }
  #disabled {
    background-color: #3a393c;
  }
}